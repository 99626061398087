import React from 'react';
import propTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styles from './video.module.scss';

const VideoPlayer = ({
    src,
    playing,
    muted,
    controls,
    loop,
    onMouseOver,
    onMouseLeave,
    onEnded,
    onModal,
}) => {
    return (
        <div
            className={
                !onModal ? styles.videoContainer : styles.videoContainerModal
            }
        >
            <ReactPlayer
                className={!onModal ? styles.video : styles.videoCoverModal}
                url={src}
                width="100%"
                height="100%"
                controls={controls}
                playing={playing}
                muted={muted}
                loop={loop}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onEnded={onEnded}
            />
        </div>
    );
};

VideoPlayer.propTypes = {
    src: propTypes.string.isRequired,
    onMouseOver: propTypes.func,
    onMouseLeave: propTypes.func,
    onEnded: propTypes.func,
    controls: propTypes.bool,
    loop: propTypes.bool,
    playing: propTypes.bool,
    muted: propTypes.bool,
    onModal: propTypes.bool,
};

VideoPlayer.defaultProps = {
    onMouseOver: () => {},
    onMouseLeave: () => {},
    onEnded: () => {},
    controls: true,
    loop: true,
    playing: false,
    muted: false,
    onModal: false,
};

export default VideoPlayer;
