import React from 'react';
import IconPlay from '@components/Icons/play.svg';
import IconPause from '@components/Icons/pause.svg';
import ctx from 'classnames';
import PropTypes from 'prop-types';
import styles from './playback-button.module.scss';

const PlaybackButton = ({ className, isPlaying, variant, ...rest }) => {
    const classes = ctx([styles.button, className]);
    const renderVariant = () => {
        if (variant === 'reel') {
            return (
                <>
                    <IconPlay className={styles.reelIcon} />
                    <span className={styles.reelText}>WATCH REEL</span>
                </>
            );
        }

        return (
            <>
                {isPlaying ? (
                    <IconPause data-testid="pause-icon" />
                ) : (
                    <IconPlay data-testid="play-icon" />
                )}
            </>
        );
    };
    return (
        <button type="button" className={classes} {...rest}>
            {renderVariant()}
        </button>
    );
};

PlaybackButton.propTypes = {
    className: PropTypes.string,
    isPlaying: PropTypes.bool,
    variant: PropTypes.oneOf(['video', 'reel']),
};

PlaybackButton.defaultProps = {
    className: '',
    isPlaying: false,
    variant: 'video',
};

export default PlaybackButton;
