import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CloseIcon from '@components/Icons/close-modal.svg';
import styles from './Modal.module.scss';

const Modal = ({
    isActive,
    isTransparent,
    children,
    handleClose,
    handleBodyModal,
}) => {
    const classesModal = cx({
        [styles.modal]: true,
        [styles.background]: isTransparent,
    });

    return isActive ? (
        ReactDOM.createPortal(
            <>
                <div className={styles.modalOverlay} />
                <div
                    className={styles.modalWrapper}
                    aria-modal
                    aria-hidden
                    tabIndex={-1}
                    role="dialog"
                    onClick={handleBodyModal}
                >
                    <div className={classesModal}>
                        <div className={styles.modalHeader}>
                            <button
                                className={styles.modalCloseButton}
                                type="button"
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div>{children}</div>
                    </div>
                </div>
            </>,
            document.body
        )
    ) : (
        <></>
    );
};

Modal.propTypes = {
    children: PropTypes.element.isRequired,
    isActive: PropTypes.bool,
    isTransparent: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleBodyModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
    isActive: false,
    isTransparent: false,
};

export default Modal;
